<template>
    <div class="tip-box">
        <i class="weui-icon-warn weui-icon_msg-primary"></i>
        <p id="msg">{{msg}}</p>
        <router-link class="container-div" to="/check/index"><p style="width: 20vw;text-align: center;">前往首页</p></router-link>
    </div>
</template>

<script>
    import { globalConfig } from '../../utils/mixin'
    export default {
        name: 'Warning',
        mixins: [globalConfig],
        components: {},
        data () {
            return {
                msg: ''
            }
        },
        methods: {
        },
        created () {
            this.msg = this.$route.params.msg
            console.log(this.$route.params)
        },
        mounted () {
        },
        computed: {
        }
    }
</script>
<style scoped>
    .tip-box {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin-top: 5rem;
    }
    .tip-box p{
        width: 80%;
        margin: 1rem auto;
        text-align: center;
    }
</style>
